import React from 'react';

const initData = {
    heading: "Disponible en todos tus dispositivos",
    headingText: "Aprende donde quieras y cuando quieras. Nuestra app está optimizada para funcionar en cualquier dispositivo, brindándote la flexibilidad de acceder a tus cursos desde tu computadora, tableta o teléfono móvil, sin interrupciones.",
    headingSlug: "* Disponible en iPhone, todos los dispositivos Android y Web",
    playImg: "/img/google-play.png",
    appleImg: "/img/app-store.png",
};

const Download = () => {
    return (
        <section className="section download-area overlay-dark ptb_100">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-9">
                        {/* Download Text */}
                        <div className="download-text text-center">
                            <h2 className="text-white">{initData.heading}</h2>
                            <p className="text-white my-3 d-none d-sm-block">{initData.headingText}</p>
                            <p className="text-white my-3 d-block d-sm-none">{initData.headingTexttwo}</p>
                            {/* Store Buttons */}
                            <div className="button-group store-buttons d-flex justify-content-center">
                                <a href="/download-page">
                                    <img src={initData.playImg} alt="Google Play" />
                                </a>
                                <a href="/download-page">
                                    <img src={initData.appleImg} alt="App Store" />
                                </a>
                            </div>
                            <span className="d-inline-block text-white fw-3 font-italic mt-3">{initData.headingSlug}</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Download;