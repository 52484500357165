import React, { useState, useEffect } from 'react';

const initData = {
    heading: "Ventajas de nuestra plataforma",
    headingText: "Nuestra app ofrece mucho más que simples videos. Es una herramienta integral para mejorar tu carrera en desarrollo.",
};

const data = [
    {
        image: "/img/featured_image_1.png",
        title: "Acceso a cursos con diploma",
        content: "Completa los cursos y obtén un diploma firmado por Quasar Solutions LLC, ideal para añadir a tu LinkedIn."
    },
    {
        image: "/img/featured_image_2.png",
        title: "Acceso al grupo privado de Slack",
        content: "Forma parte de un grupo privado para hacer preguntas detalladas y recibir asesoría directa de profesionales."
    },
    {
        image: "/img/featured_image_3.png",
        title: "Material de Apoyo",
        content: "Accede a proyectos y documentación adicional para complementar tu aprendizaje."
    },
    {
        image: "/img/featured_image_4.png",
        title: "Guarda tus cursos favoritos",
        content: "Lleva un registro de tus cursos preferidos y accede a ellos fácilmente en cualquier momento."
    }
];

const imageData = [
    {
        id: "1",
        image: "/img/screenshot_1.png"
    },
    {
        id: "2",
        image: "/img/screenshot_2.png"
    },
    {
        id: "3",
        image: "/img/screenshot_3.png"
    },
    {
        id: "4",
        image: "/img/screenshot_4.png"
    }
];

const FeatureSection = () => {
    const [initDataState, setInitDataState] = useState({});
    const [dataState, setDataState] = useState([]);
    const [imageDataState, setImageDataState] = useState([]);

    useEffect(() => {
        setInitDataState(initData);
        setDataState(data);
        setImageDataState(imageData);
    }, []);

    return (
        <section id="features" className="section features-area overflow-hidden mt-5 ptb_100">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8">
                        {/* Section Heading */}
                        <div className="section-heading text-center">
                            <h2>{initDataState.heading}</h2>
                            <p className="d-none d-sm-block mt-4">{initDataState.headingText}</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="features-slider-wrapper overflow-hidden">
                            {/* Work Slider */}
                            <ul className="features-slider owl-carousel">
                                {imageDataState.map((item, idx) => (
                                    <li key={`fi_${idx}`} className="slide-item">
                                        <img src={item.image} alt={`Screenshot ${item.id}`} />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 pt-4 pt-md-0">
                        <ul className="features-item">
                            {dataState.map((item, idx) => (
                                <li key={`ff_${idx}`}>
                                    {/* Image Box */}
                                    <div className="image-box media icon-1 px-1 py-3 py-md-4">
                                        {/* Featured Image */}
                                        <div className="featured-img mr-3">
                                            <img className="avatar-sm" src={item.image} alt={item.title} />
                                        </div>
                                        {/* Icon Text */}
                                        <div className="icon-text media-body align-self-center align-self-md-start">
                                            <h3 className="mb-2">{item.title}</h3>
                                            <p>{item.content}</p>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FeatureSection;