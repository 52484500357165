import React, { useState, useEffect } from 'react';

const initData = {
    "image": "/img/logo.png",
    "text": "Shining a Stellar Path in Software Development.",
    "linkText_1": "Enlaces Útiles",
    "linkText_2": "Soporte",
    "linkText_3": "Descargas",
    "iconList": [
        {
            "id": 1,
            "link": "facebook",
            "iconClass": "fab fa-facebook-f"
        },
        {
            "id": 2,
            "link": "instagram",
            "iconClass": "fab fa-instagram"
        },
        /* {
            "id": 3,
            "link": "tiktok",
            "iconClass": "fab fa-tiktok"
        }, */
    ],
    "footerList_1": [
        {
            "id": 1,
            "text": "Inicio"
        },
        {
            "id": 2,
            "text": "Nosotros"
        },
        {
            "id": 3,
            "text": "Servicios"
        },
        {
            "id": 4,
            "text": "Blog"
        },
        {
            "id": 5,
            "text": "Contacto"
        }
    ],
    "footerList_2": [
        {
            "id": 1,
            "text": "FAQ"
        },
        {
            "id": 2,
            "text": "Política de Privacidad"
        },
        {
            "id": 3,
            "text": "Soporte"
        },
        {
            "id": 4,
            "text": "Términos y Condiciones"
        },
        {
            "id": 5,
            "text": "Contacto"
        }
    ],
    "footerList_3": [
        {
            "id": 1,
            "image": "/img/google-play-black.png"
        },
        {
            "id": 2,
            "image": "/img/app-store-black.png"
        }
    ],
    "footerList_4": [
        {
            "id": 1,
            "text": "Affiliate program"
        },
        {
            "id": 2,
            "text": "Terms & Conditions"
        },
        {
            "id": 3,
            "text": "Privacy Policy"
        },
        {
            "id": 4,
            "text": "Refund Policy"
        }
    ]
}

const FooterSection = () => {
    const [data, setData] = useState({});
    const [iconList, setIconList] = useState([]);
    const [footerList_1, setFooterList_1] = useState([]);
    const [footerList_2, setFooterList_2] = useState([]);
    const [footerList_3, setFooterList_3] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        setData(initData);
        setIconList(initData.iconList);
        setFooterList_1(initData.footerList_1);
        setFooterList_2(initData.footerList_2);
        setFooterList_3(initData.footerList_3);
    }, []);

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div>
            <div className="height-emulator d-none d-lg-block" />
            <footer className="footer-area footer-fixed">
                {/* Footer Top */}
                <div className="footer-top ptb_100">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-lg-3">
                                {/* Footer Items */}
                                <div className="footer-items">
                                    {/* Logo */}
                                    <a className="navbar-brand" href="/#">
                                        <img className="logo" src={data.image} alt="" />
                                    </a>
                                    <p className="mt-2 mb-3">{data.text}</p>
                                    {/* Social Icons */}
                                    <div className="social-icons d-flex">
                                        {iconList.map((item, idx) => (
                                            <a key={`fi_${idx}`} className={item.link} href="https://www.facebook.com/profile.php?id=61567129436850">
                                                <i className={item.iconClass} />
                                                <i className={item.iconClass} />
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                                {/* Footer Items */}
                                <div className="footer-items">
                                    {/* Footer Title */}
                                    <h3 className="footer-title mb-2">{data.linkText_1}</h3>
                                    <ul>
                                        {footerList_1.map((item, idx) => (
                                            <li key={`flo_${idx}`} className="py-2"><a href="/#">{item.text}</a></li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                                {/* Footer Items */}
                                <div className="footer-items">
                                    {/* Footer Title */}
                                    <h3 className="footer-title mb-2">{data.linkText_2}</h3>
                                    <ul>
                                        {footerList_2.map((item, idx) => (
                                            <li key={`flt_${idx}`} className="py-2"><a href="/#">{item.text}</a></li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                                {/* Footer Items */}
                                <div className="footer-items">
                                    {/* Footer Title */}
                                    <h3 className="footer-title mb-2">{data.linkText_3}</h3>
                                    {/* Store Buttons */}
                                    <div className="button-group store-buttons store-black d-flex flex-wrap">
                                        {footerList_3.map((item, idx) => (
                                            <a key={`flth_${idx}`} href="/#">
                                                <img src={item.image} alt="" />
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Footer Bottom */}
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                {/* Copyright Area */}
                                <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                                    {/* Copyright Left */}
                                    <div className="copyright-left">© Copyrights 2024 Quasar Solutions All rights reserved.</div>
                                    {/* Copyright Right */}
                                    <div className="copyright-right">Made with <i className="fas fa-heart" /> By <a target="_blank" rel="noopener noreferrer" href="https://themeforest.net/user/theme_land">Quasar</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default FooterSection;