import React, { useState, useEffect } from 'react';

const initData = {
    "heading": "Elige tu plan",
    "headingText": "Adaptado para todos, ofrecemos opciones tanto gratuitas como premium:",
    "text": "¿Aún no estás seguro?",
    "textLink": "Contáctanos",
    "pricingData": [
        {
            "id": 1,
            "planImage": "/img/price_basic.png",
            "planTitle": "Pro",
            "priceSub": "$",
            "planPrice": "20.00",
            "planBtn": "Adquirir",
            "li_1": "Acceso a todos los cursos.",
            "li_2": "Material de apoyo sin costo adicional.",
            "li_3": "Opción de obtener diplomas avalados por nosotros.",
        },
        {
            "id": 2,
            "planImage": "/img/price_premium.png",
            "planTitle": "Premium",
            "priceSub": "$",
            "planPrice": "25.00",
            "planBtn": "Adquirir",
            "li_1": "Acceso al grupo privado de Slack con profesionales.",
            "li_2": "Respuestas detalladas a tus preguntas por expertos.",
            "li_3": "Acceso prioritario a nuevos cursos y actualizaciones.",
        }
    ]
}

const PricingSection = () => {
    const [data, setData] = useState({});

    useEffect(() => {
        setData(initData);
    }, []);

    return (
        <section id="pricing" className="section price-plan-area bg-gray overflow-hidden ptb_100">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8">
                        {/* Section Heading */}
                        <div className="section-heading text-center">
                            <h2>{data.heading}</h2>
                            <p className="d-none d-sm-block mt-4">{data.headingText}</p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-10 col-lg-8">
                        <div className="row price-plan-wrapper">
                            {data.pricingData ? data.pricingData.map((item, idx) => (
                                <div key={`p_${idx}`} className="col-12 col-md-6 mt-4">
                                    {/* Single Price Plan */}
                                    <div className="single-price-plan text-center p-5">
                                        {/* Plan Thumb */}
                                        <div className="plan-thumb">
                                            <img className="avatar-lg" src={item.planImage} alt="" />
                                        </div>
                                        {/* Plan Title */}
                                        <div className="plan-title my-2 my-sm-3">
                                            <h4 className="text-uppercase">{item.planTitle}</h4>
                                        </div>
                                        {/* Plan Price */}
                                        <div className="plan-price pb-2 pb-sm-3">
                                            <h2 className="fw-7"><small className="fw-7">{item.priceSub}</small>{item.planPrice}</h2>
                                        </div>
                                        {/* Plan Description */}
                                        <div className="plan-description">
                                            <ul className="plan-features">
                                                <li className="border-top py-3">{item.li_1}</li>
                                                <li className="border-top py-3">{item.li_2}</li>
                                                <li className="border-top py-3">{item.li_3}</li>
                                            </ul>
                                        </div>
                                        {/* Plan Button */}
                                        <div className="plan-button">
                                            <a href="/signup" className="btn mt-4">{item.planBtn}</a>
                                        </div>
                                    </div>
                                </div>
                            )): null}
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center pt-5">
                    <p className="text-body pt-4 fw-5">{data.text} <a href="mailto: community@quasarcodes.com">{data.textLink}</a></p>
                </div>
            </div>
        </section>
    );
}

export default PricingSection;